import axios from 'axios';
import maps from './images/maps/*.jpg'

(function () {

  var tabs;
  var panels;
  var buttons;
  var routesDesc;

  generateArrays();

  function generateArrays () {
    tabs = document.querySelectorAll('[role="tab"]');
    panels = document.querySelectorAll('[role="tabpanel"]');
    buttons = document.querySelectorAll('[data-route-name]');
    routesDesc = document.querySelectorAll('.routes--desc-item');
  };

  // add listeners to map-changing buttons
  for (var i = 0; i < buttons.length; ++i) {
    buttons[i].addEventListener('click', function(e) {
      resetButtons()

      this.classList.add('selected')
      document.querySelector('.routes--map-img img').src = maps[this.dataset.routeName]

      resetRoutesDesc()
      document.querySelector('#' + this.dataset.routeName + '_desc').classList.add('selected')
    })
  };

  function resetButtons() {
    for (var i = 0; i < buttons.length; ++i) {
      buttons[i].classList.remove('selected')
    }
  }

  function resetRoutesDesc() {
    for (var i = 0; i < routesDesc.length; ++i) {
      routesDesc[i].classList.remove('selected')
    }
  }

  // Bind listeners
  for (i = 0; i < tabs.length; ++i) {
    addListeners(i);
  };

  function addListeners (index) {
    tabs[index].addEventListener('click', clickEventListener);

    // Build an array with all tabs (<button>s) in it
    tabs[index].index = index;
  };

  // When a tab is clicked, activateTab is fired to activate it
  function clickEventListener (event) {
    var tab = event.target;
    activateTab(tab, false);
  };

  // Activates any given tab panel
  function activateTab (tab, setFocus) {
    setFocus = setFocus || true;
    // Deactivate all other tabs
    deactivateTabs();

    // Remove tabindex attribute
    tab.removeAttribute('tabindex');

    // Set the tab as selected
    tab.setAttribute('aria-selected', 'true');

    // Get the value of aria-controls (which is an ID)
    var controls = tab.getAttribute('aria-controls');

    // Remove hidden attribute from tab panel to make it visible
    document.getElementById(controls).removeAttribute('hidden');

    // Set focus when required
    if (setFocus) {
      tab.focus();
    };

    document.querySelector('[data-route-name=' + tab.id + '_1]').click()
  };

  // Deactivate all tabs and tab panels
  function deactivateTabs () {
    for (var t = 0; t < tabs.length; t++) {
      tabs[t].setAttribute('tabindex', '-1');
      tabs[t].setAttribute('aria-selected', 'false');
      tabs[t].removeEventListener('focus', focusEventHandler);
    };

    for (var p = 0; p < panels.length; p++) {
      panels[p].setAttribute('hidden', 'hidden');
    };
  };

  //
  function focusEventHandler (event) {
    var target = event.target;

    setTimeout(checkTabFocus, 0, target);
  };

  // Only activate tab on focus if it still has focus after the delay
  function checkTabFocus (target) {
    focused = document.activeElement;

    if (target === focused) {
      activateTab(target, false);
    };
  };


  // CONTACT FORMS

  function sendMail(data, location) {
    const notificationPlaceholder = document.getElementById(location + '-form-notification-area')
    notificationPlaceholder.innerHTML = ''

    const submitButton = document.getElementById(location + '-form-submit')
    submitButton.setAttribute('disabled', true)

    axios.post(window.location.href + 'mail.php', data)
    .then(function(response) {
    
      if (response.data.errors.length === 0) {
        
        // clean form
        document.getElementById( location + '-form-name').value = ''
        document.getElementById( location + '-form-company').value = ''
        document.getElementById( location + '-form-email').value = ''
        document.getElementById( location + '-form-tel').value = ''
        document.getElementById( location + '-form-message').value = ''

        notificationPlaceholder.innerHTML = '<div class="success">Wiadomość poprawie wysłana.</div>'

        if (location === 'hero') {
          gtag('event', 'Wysłanie formularza', {
            'event_category': 'Formularze',
            'event_label': 'Wysłany górny formularz',
          })
        } else if (location === 'bottom') {
          gtag('event', 'Wysłanie formularza', {
            'event_category': 'Formularze',
            'event_label': 'Wysłany dolny formularz',
          })
        }

      } else {

        if (response.data.errors.includes('email_invalid') || response.data.errors.includes('email_empty')) {
          notificationPlaceholder.innerHTML = '<div class="error">Niepoprawny adres e-mail.</div>'
        } else {
          notificationPlaceholder.innerHTML = '<div class="error">Błąd wysyłania wiadomości.</div>'
        }
      }

      submitButton.removeAttribute('disabled')
      
    })
    .catch(function (error) {
      notificationPlaceholder.innerHTML = '<div class="error">Błąd wysyłania wiadomości.</div>'
      submitButton.removeAttribute('disabled')
    });
  }

  document.getElementById('hero-form').addEventListener('submit', function(e) {
    e.preventDefault()

    let data = {
      name: document.getElementById('hero-form-name').value,
      company: document.getElementById('hero-form-company').value,
      email: document.getElementById('hero-form-email').value,
      tel: document.getElementById('hero-form-tel').value,
      message: document.getElementById('hero-form-message').value,
    }
    
    sendMail(data, 'hero')
  })

  document.getElementById('bottom-form').addEventListener('submit', function(e) {
    e.preventDefault()

    let data = {
      name: document.getElementById('bottom-form-name').value,
      company: document.getElementById('bottom-form-company').value,
      email: document.getElementById('bottom-form-email').value,
      tel: document.getElementById('bottom-form-tel').value,
      message: document.getElementById('bottom-form-message').value,
    }
    
    sendMail(data, 'bottom')
  })

  // google analytics
  document.querySelector('.mobile-bubble').addEventListener('click', function() {

    gtag('event', 'Kliknięcie przycisku', {
      'event_category': 'Przyciski kontaktu',
      'event_label': 'Kliknięcie na "bąbel" telefonu',
    })
  })

  document.querySelector('.contact--channels-phone').addEventListener('click', function() {

    gtag('event', 'Kliknięcie przycisku', {
      'event_category': 'Przyciski kontaktu',
      'event_label': 'Kliknięcie na telefon w dolnej sekcji',
    })
  })

  document.querySelector('.contact--channels-email').addEventListener('click', function() {

    gtag('event', 'Kliknięcie przycisku', {
      'event_category': 'Przyciski kontaktu',
      'event_label': 'Kliknięcie na email w dolnej sekcji',
    })
  })
  
}());